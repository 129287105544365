import { environmentBase, mergeEnviroments } from './environment.base';

export const environment = {
	production: true,
	...mergeEnviroments(environmentBase, {
		URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',

		HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',

		HOST_BANCOS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-bancos/api',
		HOST_VENTAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-ventas/api',
		HOST_COMPRAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-compras/api',
		HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
		HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',
		HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',
		HOST_BASE_URL_MAESTROS: 'https://dev-maestros.sintad.net.pe/#/',
		HOST_BASE_URL_CARGA: 'https://dev-carga.sintad.net.pe/#/',
		HOST_CARGA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-carga/api',
		HOST_ACUERDO_COMERCIAL: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-acuerdocomercial/api',
		HOST_DOC_OPER: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-documentos-operativos/api',

		// Redireccionamiento al módulo de Maestros
		URL_MAESTROS: 'https://dev-maestros.sintad.net.pe/#/maestros',
		URL_CARGA: 'https://dev-carga.sintad.net.pe/#/carga',
	}),
	MQTT_PASSWORD: 'scmj010400*212598',
};
